import styled from "@emotion/styled"
import { mq, theme } from '../../util/theme'
import { css, keyframes } from "@emotion/core"

import Carat from '../../images/select-carat.svg'
import FormCheck from "../../images/form-check.svg"

export const FormBlock = styled.section`
  border-bottom: 1px solid ${props => props.theme.colors.hr};
  padding: 2rem 0;
  margin-bottom: 2rem;
`;

export const FormRow = styled.div`
  margin-bottom: 1.5rem;
  &:last-of-type {
    margin: 0;
  }
  ${mq[2]} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &.is-start {
    ${mq[2]} {
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
  &.is-radiogroup {
    max-width: 200px;
    width: 100%;
  }
  &.is-end {
    margin-bottom: 3rem;
  }
`;

export const FormField = styled.div`
  width: 100%;
  ${mq[2]} {
    max-width: 300px;
  }
  &.is-radio {
    display: flex;
    align-items: center;
    max-width: 250px;
    margin-bottom: .5rem;
    ${mq[2]} {
      max-width: 250px;
    }
    &.is-short {
      width: auto;
    }
  }
  &.is-long {
    max-width: 450px;
  }
  &.is-check {
     ${mq[2]} {
      max-width: 250px;
    }
  }
  &.is-full {
    max-width: 100%;
  }
  &.is-checkgroup {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -.5rem;
    margin-bottom: 3rem;
  }
`;

export const CheckWrap = styled.div`
  max-width: 320px;
  width: 100%;
  margin-bottom: 1rem;
  &.is-radio {
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  &.is-full {
    max-width: 100%;
    margin-bottom: 1.5rem;
    .is-long {
      max-width: 550px;
    }
  }
  .is-radio {
    &:before {
      margin-right: .75rem;
    }
  }
`;

export const FormInput = styled.input`
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.roboto};
  background: ${props => props.theme.colors.white};
  display: block;
  width: 100%;
  padding: .6rem 1rem;
  margin-bottom: 2rem;
  &.no-marg {
    margin-bottom: 0;
  }
  ${mq[2]} {
    margin-bottom: 0;
  }
  &:focus {
    outline-color: ${props => props.theme.colors.teal};
  }
  &.is-radio {
    margin-bottom: 0;
  }
  &::placeholder {
    font-size: ${props => props.theme.fontSizes.sm};
    opacity: .5;
  }
`;

export const FormLabel = styled.label`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.sm};
  font-family: ${props => props.theme.fonts.roboto};
  display: block;
  margin-bottom: 1rem;
  &.is-radio {
    margin: 0;
    font-size: ${props => props.theme.fontSizes.sm};
  }
  &.is-long {
    max-width: 450px;
  }
  &.is-hidden {
    display: none;
  }
`;

export const FormSelect = styled.select`
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: 8px;
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.roboto};
  background: url(${Carat}) no-repeat 95%/contain;
  background-size: .75rem .6rem;
  display: block;
  width: 100%;
  padding: .875rem 1rem;
  appearance: none;
`;

export const FormRadio = css`
  color: ${theme.colors.copy};
  font-size: ${theme.fontSizes.sm};
  font-family: ${theme.fonts.copy};
  background: ${theme.colors.white};
  display: flex;
  margin-right: .5rem;
  width: auto;
`;

export const Checkbox = css`
  input[type="checkbox"] {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px!important;
    overflow: hidden;
    &:checked ~ label{
        &:before {
            border: 2px solid ${theme.colors.robins};
            background: ${theme.colors.robins};
        }
        &:after {
            content: "";
            background: url(${FormCheck}) no-repeat center/contain;
            position: absolute;
            left: .125rem;
            height: .875rem;
            width: .875rem;
            top: .125rem;
        }
    }
     &:checked + .is-long {
        &:after {
            bottom: .75rem;
            ${mq[2]} {
              bottom: auto;
              top: .125rem;
            }
        }
    }
  }
  label {
      width: 100%;
      cursor: pointer;
      position: relative;
      color: ${theme.colors.label};
      font-size: ${theme.fontSizes.reg};
      font-weight: ${theme.weights.bold};
      font-family: ${theme.fonts.copy};
      background: ${theme.colors.white};
      letter-spacing: ${theme.leading.tiny};
      text-transform: uppercase;
      display: flex;
      align-items: flex-start;
      margin: .5rem 0 1rem;
      &:before {
        content: "";
        min-height: 1.125rem;
        min-width: 1.125rem;
        margin-right: 1rem;
        border: 2px solid ${theme.colors.robins};
        background: ${theme.colors.white};
        transition: all 0.15s ease-in-out;
      }
      &:hover {
        &:before {
          border: 2px solid ${theme.colors.robins};
          background: ${theme.colors.pearl};
        }
      }
      ${mq[2]} {
        max-width: 225px;
      }
      &.is-full {
        max-width: 100%;
      }
    }
`;

export const Radio = css`
  input[type="radio"] {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px!important;
    overflow: hidden;
    &:checked ~ label {
      &:after {
          content: "";
          background: ${theme.colors.robins};
          position: absolute;
          left: 3px;
          top: 3px;
          margin: auto;
          height: 0.75rem;
          width: .75rem;
          border-radius: 100%;
      }
    }
  }
  label {
      width: 100%;
      cursor: pointer;
      position: relative;
      color: ${theme.colors.copy};
      font-size: ${theme.fontSizes.sm};
      font-family: ${theme.fonts.copy};
      background: ${theme.colors.white};
      display: flex;
      align-items: flex-start;
      margin: 0;
      &:before {
        content: "";
        min-height: 1.125rem;
        min-width: 1.125rem;
        margin-right: 10px;
        border: 2px solid ${theme.colors.border};
        border-radius: 100%;
        background: ${theme.colors.white};
        transition: all 0.15s ease-in-out;
      }
      &:hover {
        &:before {
          border: 2px solid ${theme.colors.robins};
          //background: ${theme.colors.pearl};
        }
      }
      ${mq[2]} {
        //max-width: 25px;
      }
      &.is-full {
        max-width: 100%;
      }
    }
`;

export const ErrorMsg = styled.p`
  color: ${props => props.theme.colors.teal};
  font-family: ${props => props.theme.fonts.roboto};
  font-weight:  ${props => props.theme.weights.bold};
  font-size: ${props => props.theme.fontSizes.sm};
  margin: .5rem 0 0;
  &.is-disabled {
    display: none;
  }
  &.is-discount {
    color: ${props => props.theme.colors.teal};
  }
  &.is-decline {
    margin: 0 0 1rem;
  }
  &.radio-error {
    margin: -1rem 0 2rem;
  }
`;

export const Btn = styled.a`
  background: ${props => props.theme.colors.robins};
  border: 1px solid ${props => props.theme.colors.robins};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.robCondensed};
  font-weight:  ${props => props.theme.weights.semi};
  letter-spacing: ${props => props.theme.leading.mini};
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  //border-radius: 4px;
  padding: .75rem;
  max-width: 140px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  transition: all .2s ease-in-out;
  appearance: none;
  position: relative;
  white-space: nowrap;
  margin: 3rem 0 0;
  &:before,
  &:after {
    transition: all .2s ease-in-out;
  }
  &:hover,
  &:active,
  &:focus {
    background:  ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.blue};
  }
  &:disabled {
    cursor: not-allowed;
    background: ${props => props.theme.colors.gray};
    border: 1px solid ${props => props.theme.colors.gray};
  }
  &.is-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: ${props => props.theme.colors.gray};
    border: 1px solid ${props => props.theme.colors.gray};
  }
  &.big-pad {
    padding: .75rem 2rem;
    &:active,
    &:focus,
    &:hover {
      background: ${props => props.theme.colors.blue};
      border: 1px solid ${props => props.theme.colors.blue};
      color: ${props => props.theme.colors.white};
      &:disabled {
        cursor: not-allowed;
        background: ${props => props.theme.colors.gray};
        border: 1px solid ${props => props.theme.colors.gray};
      }
      &.is-disabled {
        cursor: not-allowed;
        pointer-events: none;
        background: ${props => props.theme.colors.gray};
        border: 1px solid ${props => props.theme.colors.gray};
      }
    }
  }
`;

const rotation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
`;

export const Spinner = styled.div`
  height: 12px;
  width: 12px;
  margin-left: 5px;
  position: absolute;
  right: 0;
  top: 0;
  animation: ${rotation} 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
`;

export const ModalBody = styled.section`
  background: ${props => props.theme.colors.white};
  position: relative;
  max-width: 1300px;
  width: 100%;
  padding: 3rem;
  &.confirmation {
    max-width: 500px;
    padding: 2rem;
  }
`;

export const ModalClose = styled.button`
  background: transparent;
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: -.75rem;
  top: -.75rem;
  z-index: 99999;
  &:focus {
    outline: 0;
  }
`;

export const SectionSubtitle = styled.h3`
  color: ${props => props.theme.colors.blue};
  font-size: ${props => props.theme.fontSizes.h2};
  font-family: ${props => props.theme.fonts.heading};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: -1rem 0 1.5rem;
  &.is-sub {
    color: ${props => props.theme.colors.copy};
    font-size: ${props => props.theme.fontSizes.h3};
    font-family: ${props => props.theme.fonts.copy};
    font-weight:  ${props => props.theme.weights.bold};
  }
  &.has-top-marg {
    margin-top: 4rem;
  }
  &.centered {
    margin: 1rem 0 .5rem;
    text-align: center;
  }
`;

export const SectionCopy = styled.div`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.regPlus};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  line-height: 1.5;
  margin: 0 0 1rem;
  &.is-form {
    margin: -.5rem 0 2rem;
  }
  &.is-disclaimer {
    font-size: ${props => props.theme.fontSizes.sm};
  }
  &.centered {
    text-align: center;

  }
  a {
    color: ${props => props.theme.colors.copy};
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.blue};
      cursor: pointer;
    }
  }
  p{
    display: inline;
  }
`;
